import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';

import '../css/pages/_products-page.scss';

export default function products({ data }) {
  const pageData = data.sanityProductsPage;
  const allProducts = data.allSanityProduct.nodes;

  return (
    <Layout>
      <SEO title="Products" />

      <div className="products-page">
        {/* Hero section */}
        <Hero h1={pageData.title} p={pageData.desc} />

        {/* Products listing */}
        <section className="all-products">
          <div className="wrapper">
            {allProducts.map((product, i) => (
              <div className="product" key={i}>
                <div className="thumb">
                  <GatsbyImage
                    image={product.mainImage.asset.gatsbyImageData}
                    alt={product.mainImage.alt}
                    className="img"
                  />
                </div>
                <h4>{product.title}</h4>
                <p>{product.category}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    sanityProductsPage {
      title
      desc
    }
    allSanityProduct(sort: { fields: order }) {
      nodes {
        title
        category
        mainImage {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
